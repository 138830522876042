/* Google Fonts Import Link */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

/* Global Styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
  font-weight: 450;
  color: #27366b;
}

/* Home Section Styling */

.home-content {
  display: flex;
  flex-direction: column; /* Stack elements vertically */
  align-items: center; /* Centers horizontally within home-content */
  justify-content: center; /* Centers vertically within home-content */
  width: 100%;
  height: 100%;
}

/*--- Loading Screen Styling ---*/
#loading-screen {
    background: url('../images/loading.gif') no-repeat center center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    z-index: 9999;
    background-size: 800px;
    background-color: rgba(255, 255, 255, 0.9);
  }