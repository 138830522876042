/* Google Fonts Import */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

/* Global Styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}

/* Home Section */
.home-section {
  position: relative;
  background: #F1F0EA;
  height: 100vh;
  margin-left: 260px;
  width: calc(100% - 260px);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
  transition: margin-left 0.5s ease, width 0.5s ease;
}

.sidebar.collapsed ~ .home-section {
  margin-left: 80px;
  width: calc(100% - 80px);
}

/* Sidebar */
.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 260px;
  background: #fff;
  z-index: 1;
  transition: width 0.5s ease;
}

.sidebar.collapsed {
  width: 80px;
}

/* Hide link names and submenu lists when collapsed */
.sidebar.collapsed .link_name,
.sidebar.collapsed .submenu-list {
  display: none;
}

.sidebar.collapsed .bx-chevron-right {
  display: none;
}

/* Logo */
.logo-details {
  height: 110px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: height 0.3s ease, width 0.3s ease;
}

.logo-details .logo-img {
  width: 100px;
  transition: width 0.3s ease;
}

.sidebar.collapsed .logo-details .logo-img {
  width: 50px;
}

/* Nav Links */
.nav-links {
  height: calc(100vh - 150px);
  padding: 0 0 150px 0;
  overflow: auto;
}

.nav-links::-webkit-scrollbar {
  display: none;
}

.nav-links li {
  position: relative;
  list-style: none;
}

.nav-links li:hover {
  background: #eee;
}

.nav-links li i {
  height: 45px;
  min-width: 80px;
  text-align: center;
  line-height: 45px;
  color: #4056a1;
  font-size: 20px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.nav-links li a,
.nav-links li div {
  display: flex;
  align-items: center;
  text-decoration: none;
}

/* Ensure link names do not wrap */
.nav-links li a .link_name {
  font-size: 18px;
  white-space: nowrap;
}

/* Submenu Styling */
.submenu .submenu-toggle {
  display: flex;
  align-items: center;
  height: 45px;
  cursor: pointer;
}

.submenu .submenu-toggle i {
  min-width: 80px;
  text-align: center;
  line-height: 45px;
  color: #4056a1;
  font-size: 20px;
  transition: all 0.3s ease;
}

.submenu .submenu-toggle .link_name {
  flex-grow: 1;
  font-size: 18px;
  white-space: nowrap;
}

.submenu .arrow {
  line-height: 45px;
  transition: transform 0.3s;
}

.submenu.open .arrow {
  transform: rotate(90deg);
}

/* Hide submenu list when not open; show when open */
.submenu .submenu-list {
  display: none;
  padding-left: 40px;
  transition: all 0.3s ease;
}

.submenu.open .submenu-list {
  display: block;
}

/* Prevent submenu link text from wrapping */
.submenu-list li a {
  white-space: nowrap;
  display: flex;
  align-items: center;
  height: 45px;
  line-height: 45px;
  font-size: 18px;
  text-decoration: none;
  padding-left: 20px;
}

.submenu-list li:hover {
  background: #ddd;
}

/* Profile */
.profile-container {
  position: fixed;
  bottom: 0;
  width: 260px;
  background: #fff;
  padding-bottom: 12px;
  transition: all 0.5s ease;
}

.sidebar.collapsed .profile-container {
  width: 80px;
}

.profile-details {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 0;
}

.sidebar.collapsed .profile-details {
  justify-content: center;
}

.profile-details .profile-content {
  display: flex;
  align-items: center;
  flex-shrink: 0;
}

.profile-details img {
  height: 52px;
  width: 52px;
  object-fit: cover;
  border-radius: 15px;
  margin: 0 14px 0 12px;
  background: #293462;
  transition: all 0.5s ease;
}

.profile-details .name-job {
  flex-grow: 1;
  text-align: left;
  transition: all 0.5s ease;
  overflow: hidden;
}

.sidebar.collapsed .profile-details .name-job {
  width: 0;
  opacity: 0;
}

.profile-details .profile_name,
.profile-details .job {
  font-size: 18px;
  font-weight: 500;
  white-space: nowrap;
}

.profile-details .job {
  font-size: 12px;
}

/* Socials */
.socials-link {
  display: flex;
  justify-content: center;
  text-align: center;
  transition: all 0.5s ease;
}

.socials-placeholder {
  height: 20px;
}

.socials-link a {
  display: flex;
  align-items: center;
  text-decoration: none;
  transition: all 0.4s ease;
}

.socials-link i {
  min-width: 30px;
  text-align: center;
  color: #4056a1;
  font-size: 20px;
  cursor: pointer;
}

.expanded-socials {
  display: flex;
}
