.main-container {
  width: 100%;
  margin: auto;
  font-family: 'Inter', sans-serif;
  min-height: 100vh;
  position: relative; /* Needed for absolute positioning of children */
}

.chat-container {
  position: fixed;
  top: 20px; /* Adjust as needed */
  left: 50%;
  transform: translateX(-45%);
  width: 100%;
  padding-left: calc(20px + max((100vw - 800px) / 2, 0px));
  padding-right: calc(20px + max((100vw - 800px) / 2, 0px));
  max-height: 80vh;
  overflow-y: auto;
  box-sizing: border-box;
}

/* Add extra left padding for lists to avoid bullets/numbers touching the edge */
.chat-container ul,
.chat-container ol {
  padding-left: 1.5em;
}


  
  /* Updated message styling so the background only fits the content */
  .message {
    padding: 10px;
    /* Default alignment for user messages */
    text-align: right;
  }
  
  .message.assistant {
    /* Right-align assistant messages */
    text-align: left;
  }
  
  .message-content {
    display: inline-block;
    max-width: 100%;
    padding: 20px;
    border-radius: 12px;
    font-size: 15px;
    line-height: 1.6;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
    /* Default styling for user message background */
    background: #ffffff;
    border: 1px solid #e5e7eb;
  }
  
  .message.assistant .message-content {
    /* Styling for assistant message background */
    background: #f3faf7;
    border: 1px solid #2dc08d30;
  }

.message.user {
  border-color: #e5e7eb;
}

  
  .message-header {
    margin-bottom: 12px;
    font-weight: 600;
    font-size: 14px;
    display: flex;
    align-items: center;
    gap: 8px;
  }
  
  .message-avatar {
    width: 24px;
    height: 24px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .user .message-avatar {
    background: #e5e7eb;
    color: #374151;
    align-items: right;
  }
  
  .assistant .message-avatar {
    background: #2dc08d20;
    color: #2dc08d;
  }
  
  
  .user .message-content {
    color: #374151;
  }
  
  .assistant .message-content {
    color: #1f2937;
  }
  
  .dropdown-container {
    margin: 20px 0;
    display: grid;
    gap: 15px;
  }
  
  .dropdown-label {
    display: block;
    margin-bottom: 8px;
    color: #374151;
  }
  
  .dropdown {
    width: 100%;
    padding: 12px;
    background: #ffffff;
    border: 1px solid #e5e7eb;
    color: #374151;
    border-radius: 8px;
    font-size: 14px;
  }
  
  .input-container {
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translateX(-40%);
    width: 800px;
    max-width: 80%;
    padding: 12px 24px;
  }
  
  
  .chat-input {
    width: 100%;
    padding: 12px 60px 12px 15px;
    background: #ffffff;
    border: 1px solid #e5e7eb;
    color: #374151;
    border-radius: 6px;
    min-height: 100px;
    resize: vertical;
  }
  
  .send-button {
    position: absolute;
    right: 34px;
    bottom: 25px;
    background: #2dc08d;
    color: #fff;
    border: none;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .send-button:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
  
  .control-bar {
    margin-top: 15px;
    display: flex;
    gap: 10px;
    align-items: center;
  }
  
  .clear-button {
    background: #3a3a3a;
    color: #fff;
    border: none;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .checkbox-group {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  
  .code-block-wrapper {
    position: relative;
  }
  
  .copy-button {
    position: absolute;
    right: 8px;
    top: 8px;
    z-index: 1;
    background: #2dc08d;
    border-radius: 4px;
    padding: 4px;
    cursor: pointer;
    transition: all 0.2s;
  }
  
  .loading-container {
    padding: 15px;
    color: #888;
    text-align: center;
    font-style: italic;
  }
  
  @keyframes dot-flash {
    0%, 80%, 100% { opacity: 0; }
    40% { opacity: 1; }
  }
  
  .loading-dots {
    display: inline-flex;
    align-items: center;
    gap: 4px;
  }
  
  .dot {
    width: 6px;
    height: 6px;
    background: #888;
    border-radius: 50%;
    animation: dot-flash 1.4s infinite linear;
  }
  
  .dot:nth-child(2) {
    animation-delay: 0.2s;
  }
  
  .dot:nth-child(3) {
    animation-delay: 0.4s;
  }
  
  .settings-icon {
    position: absolute;
    top: 20px;
    right: 35px;
    cursor: pointer;
  }
  
  .settings-dropdown {
    position: absolute;
    bottom: 100%; /* positions the dropdown above its trigger */
    right: 35px;
    margin-bottom: 8px; /* optional gap */
    background: #ffffff;
    padding: 12px 16px;
    border: 1px solid #e5e7eb;
    border-radius: 8px;
    color: #374151;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .settings-dropdown select {
    width: 100%;
    margin-top: 5px;
    padding: 6px;
    border: 1px solid #e5e7eb;
    border-radius: 4px;
    background: #fff;
    color: #374151;
  }
  
  .think-box {
    font-size: 14px;
    font-style: italic;
    color: #888;
    padding: 4px 8px;
    border-left: 3px solid #aaa;
    margin: 4px 0;
    display: inline-block;
    max-width: 90%;
    transition: all 0.2s ease-in-out;
  }
  
  .stop-button {
    background: red;
    position: absolute;
    left: 34px;
    bottom: 25px;
    color: #fff;
    border: none;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .stop-button:hover {
    background: darkred;
  }

 