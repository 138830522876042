/* Analytics Page Styling */
.analytics-page {
    width: 100%;
    max-width: 600px; /* Wider than swap-box to accommodate more data */
    background-color: #ffffff;
    padding: 40px 30px 30px 30px;
    border-radius: 20px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    margin: 40px auto; /* Center the box horizontally and add top margin */
}

/* Title Styling */
.analytics-page h2 {
    margin-bottom: 20px;
    text-align: center;
}

/* Analytics Price Section Styling */
.analytics-price-section {
    width: 100%;
    max-width: 450px;
    margin: 20px auto; /* Center the section horizontally */
    padding: 20px;
    background-color: #ffffff;
    border-radius: 10px;

}

.analytics-info {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.analytics-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 0;
    border-bottom: 1px solid #eee;
}

.analytics-row:last-child {
    border-bottom: none;
}

.analytics-label {
    font-weight: bold;
    font-size: 16px;
    color: #333;
}

.analytics-value {
    font-size: 14px;
    color: #333;
    text-align: right;
}


/* Analytics Table Styling */
.analytics-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

.analytics-table th,
.analytics-table td {
    padding: 12px 15px;
    border: 1px solid #ddd;
    text-align: center;
    font-size: 16px;
}

.analytics-table th {
    background-color: #f9f9f9;
    font-weight: bold;
}

.analytics-table tr:nth-child(even) {
    background-color: #f1f0ea;
}

.analytics-table tr:hover {
    background-color: #f5f5f5;
}

/* Responsive Design */
@media screen and (max-width: 600px) {
    .analytics-page {
        padding: 20px;
        margin: 20px auto;
    }

    .analytics-table th,
    .analytics-table td {
        padding: 10px;
        font-size: 14px;
    }

    .erth-price-section h3 {
        font-size: 18px;
    }
}

/* Loading Message Styling */
.loading-message {
    text-align: center;
    font-size: 18px;
    margin-top: 50px;
}

/* Error Message Styling */
.error-message {
    color: red;
    text-align: center;
    font-size: 18px;
    margin-top: 50px;
}
